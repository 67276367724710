import { useState, useEffect } from 'react';
import { claimToken, getComicLink, getUserAuthData, updateToken } from '../services/API';
import { addToClipBoard } from '../helpers/helpers';
import { useSelector } from 'react-redux';
import { saveUserData } from '../Redux/actions';
import { useDispatch } from 'react-redux';
import QRCode from "react-qr-code";
import '../modal.css';
import Modal from "react-modal";
import store from '../Redux/store';
import fileDownload from 'js-file-download'
import axios from 'axios'
import updateUserEmail from '../Redux/updateUserEmail';
import getUserEmail from '../Redux/getUserEmail';

import {
	pairHashpack,
	initConnection,
	disconnect,
	associate,
	disassociate,
	manualListener
} from '../hashconnect';
import updateUserData from '../Redux/updateUser';
import updateWalletData from '../Redux/updateWallet';
import validator from 'validator';

function Home() {
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [pairingString, setPairingString] = useState('');
	const [loadingMessage, setLoadingMessage] = useState("Loading ...");
	const [successFul, setSuccessful] = useState(false);
	const [successMessage, setSuccessMessage] = useState("");
	const [amount, setAmount] = useState(0);
	const userId = useSelector((state: any) => state.user.userId);
	const isFirstChapterAssociated = useSelector((state: any) => state.user.isFirstChapterAssociated)
	const isSecondChapterAssociated = useSelector((state: any) => state.user.isSecondChapterAssociated)
	const isThirdChapterAssociated = useSelector((state: any) => state.user.isThirdChapterAssociated)
	const isFirstChapterOwner = useSelector((state: any) => state.user.isFirstChapterOwner)
	const isSecondChapterOwner = useSelector((state: any) => state.user.isSecondChapterOwner)
	const isThirdChapterOwner = useSelector((state: any) => state.user.isThirdChapterOwner)
	const isFounder = useSelector((state: any) => state.user.isFounder)
	const isConnected = useSelector((state: any) => state.user.isConnected)
	const isFakeWallet = useSelector((state: any) => state.wallet.isFakeWallet)
	const walletLoginEmailAddress = useSelector((state: any) => state.user.walletLoginEmailAddress);
	const [isEmailValidForHashpackLogin, setIsEmailValidForHashpackLogin] = useState(false);
	const [isClicked, setIsClicked] = useState(false)
	const [emailAddress, setEmailAddress] = useState('');
	const [password, setPassword] = useState('');
	const [isCreating, setIsCreating] = useState(false);
	const [isPasswordMatch, setIsPasswordMatch] = useState(true)
	const [errorMessage, setErrorMessage] = useState('') 
	const [username, setUsername] = useState('') 



	useEffect(() => {
		getUserData();
	}, []);

	
    const validatePassword = (pass: string) => { 
  
        if (validator.isStrongPassword(pass, { 
            minLength: 8, minLowercase: 1, 
            minUppercase: 1, minNumbers: 1, minSymbols: 1 
        })) { 
            setErrorMessage('') 
        } else { 
            setErrorMessage('Not Strong Password') 
        } 
    } 

	async function closeFakeWalletModal() {
		updateWalletData(false)
	}

	let validateEmailAddressForHashpakLogin = () => {
		const status = checkEmailAddress(walletLoginEmailAddress);
		setIsEmailValidForHashpackLogin(status);
	}

	let checkEmailAddress = (email: string) => {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	const handleChange = async (email: string) => {
		updateUserEmail(email);
		setIsClicked(false)
	}

	const handlePasswordChange = (pass: string) => {
		setPassword(pass);
	}

	const handleUsernameChange = (nickname: string) => {
		setUsername(nickname);
	}
	
	const handlePasswordMatch = (pass: string) => {
		if(pass === password){
			setIsPasswordMatch(true)
		}else{
			setIsPasswordMatch(false)
		}
	}

	const handleEmailChange = (email: string) => {
		setEmailAddress(email);
	}

	async function getUserData() {
		try {
			setLoadingMessage("Fetching user Data")
			setLoading(true)
			let data = localStorage.getItem("accessToken");
			if (data) {
				let config = {
					method: 'get',
					maxBodyLength: Infinity,
					url: process.env.REACT_APP_API_BASE_URL + '/getUser/get',
					headers: {
						'Authorization': 'Bearer ' + data
					}
				};


				const authData = await getUserAuthData();
				const id = authData.id;
				if (id) {
					updateUserData(id, authData, true)
				}
				setLoading(false)
				setLoadingMessage("Loading...")

			} else {
				setLoading(false)
				setLoadingMessage("Loading...")
			}
		} catch (error) {

		}
	}



	async function browserExtension() {
		try {
			setLoadingMessage("Pairing...")
			setLoading(true)
			await pairHashpack();
			closeModal();
			setLoading(false)
			setLoadingMessage("Loading...")
		} catch (error) {

		}
	}

	async function disconnectBrowserExtension() {
		try {
			setLoadingMessage("Disconnecting...")
			setLoading(true)
			await disconnect();
			updateUserData("", [], false)
			setLoading(false)
			setLoadingMessage("Loading...")
		} catch (error) {

		}
	}

	async function openModal() {
		try {
			setIsOpen(true);
			const saveData = await initConnection()
			setPairingString(saveData.pairingString)
			manualListener(setIsOpen, setLoading)
		} catch (error) {

		}
	}

	async function closeModal() {
		setIsOpen(false);
	}


	async function claim(token: string) {
		try {
			setLoadingMessage("Generating Transaction...")
			setLoading(true)
			const operation: any = await claimToken(token);

			if (operation.status == true) {
				const status: any = await updateToken(operation.serials, operation.tokenId)
				if (status == true) {
					setSuccessMessage(" Token(s) sent successfully!")
					setSuccessful(true)
					showSuccessModal(1)
				} else {
					setSuccessMessage(" Something went wrong.")
					setSuccessful(false)
					showSuccessModal(1)
				}
			}
			setLoading(false)
			setLoadingMessage("Loading...")
		} catch (error) {

		}
	}
	async function showSuccessModal(tokens: any) {
		try {
			setAmount(tokens)
			// setSuccessful(true);
			await getUserData();
		} catch (error) {

		}
	}

	async function associateToken() {
		try {
			setLoading(true)
			await associate();
			setLoading(false)
			getUserData()
			// showSuccessModal(tokens)
		} catch (error) {

		}

	}

	async function disAssociateToken() {
		try {
			setLoading(true)
			await disassociate();
			setLoading(false)
			await getUserData()
			// showSuccessModal(tokens)
		} catch (error) {

		}

	}




	interface FormDataType {
		email: string,
		password: string,
		username?: string,
		method?: string
	}

	const requestBody: FormDataType = {
		email: "",
		password: "",
		method: "login"
	}
	const signUpRequestBody: FormDataType = {
		email: "",
		password: "",
		username: "",
		method: "signUp"
	}

	const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		requestBody.email = emailAddress
		requestBody.password = password
 

		const data = await fetch('/index.php?option=com_ajax&plugin=ajaxlogin&format=json', {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(requestBody),
		}).then((response) => response.json());
		console.log(data)

	}

	const onRegistrySubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		signUpRequestBody.email = emailAddress
		signUpRequestBody.password = password
		signUpRequestBody.username = username

		const data = await fetch('/index.php?option=com_ajax&plugin=ajaxlogin&format=json', {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(signUpRequestBody),
		}).then((response) => response.json());
		console.log(data)

	}

	return (
		<>

			<center>
				<div className="wrapper">
					{isCreating ? (
						<>
						<form className="form-signin" onSubmit={onRegistrySubmitHandler}>
						<h3 className="form-signin-heading text-left card-header">Sign Up</h3>
						<input type="email" className="form-control" placeholder="Email Address" autoComplete="off" required onInput={(e: any) => { handleEmailChange(e.target.value) }} />
						<input type="text" className="form-control mt-2" placeholder="Username"  autoComplete="off" required onInput={(e: any) => { handleUsernameChange(e.target.value) }} />
						<input type="password" className="form-control mt-2" placeholder="Password" autoComplete="off" required onInput={(e: any) => { handlePasswordChange(e.target.value); validatePassword(e.target.value) }} />
						{errorMessage !== '' ? (
							<>
							<div className='text-left'>
								<h3 className='modal-text  ml-1 mb-2 mt-1 text-danger'>
									<strong>Password not strong enough *</strong>
								</h3>
							</div>
							</>
						) : (
							<></>
						)
						}
						<input type="password" className="form-control mt-2" placeholder="Retype Password" autoComplete="off" required onInput={(e: any) => { handlePasswordMatch(e.target.value) }} />
						{(!isPasswordMatch) ? (
							<>
							<div className='text-left'>
								<h3 className='modal-text  ml-1 mb-2 mt-1 text-danger'>
									<strong>Password mismatch *</strong>
								</h3>
							</div>
							</>
						) : (
							<></>
						)
						}
						<div className='row'>
							<div className='col-md-6'>
								<button className="btn btn-lg btn-primary btn-block mt-2" type="submit" >Sign Up</button>
							</div>
							<div className='col-md-6'>
								{userId ? (
									<button className="btn btn-dark rounded mx-auto d-block" id="accountid" onClick={disconnectBrowserExtension}>
										<img src="/hashpack/img/hashpackLogo.png" width="30" alt="hedera logo" /> Connect Your Wallet</button>
								) : (
									<a className="btn btn-lg btn-dark rounded  btn-block mt-2" id="accountid" onClick={openModal}>
										<img src="/hashpack/img/hashpackLogo.png" width="30" alt="hedera logo" />
									</a>
								)
								}
							</div>
						</div>
					</form>
						</>
					) : (
                        <>
						<form className="form-signin" onSubmit={onSubmitHandler}>
						<h3 className="form-signin-heading text-left card-header">Please login</h3>
						<input type="email" className="form-control" name="email" id='email' placeholder="Email Address" required autoComplete="off" onInput={(e: any) => { handleEmailChange(e.target.value) }} />
						<input type="password" className="form-control mt-2" name="password" id="password" placeholder="Password" autoComplete="off"  required onInput={(e: any) => { handlePasswordChange(e.target.value) }} />
						{/* <label className="checkbox">
							<input type="checkbox" value="remember-me" id="rememberMe" name="rememberMe" /> Remember me
						</label> */}
						<div className='mt-2 text-left'>
						<label>
							Not registered? <span className='text-primary' onClick={(e) => { setIsCreating(true)}}>Create an account.</span>
						</label>
						</div>
				 
						<div className='row'>
							<div className='col-md-6'>
								<button className="btn btn-lg btn-primary btn-block mt-2" type="submit" >Login</button>
							</div>
							<div className='col-md-6'>
								{userId ? (
									<button className="btn btn-dark rounded mx-auto d-block" id="accountid" onClick={disconnectBrowserExtension}>
										<img src="/hashpack/img/hashpackLogo.png" width="30" alt="hedera logo" /> Connect Your Wallet
									</button>
								) : (
									<a className="btn btn-lg btn-dark rounded  btn-block mt-2" id="accountid" onClick={openModal}>
										<img src="/hashpack/img/hashpackLogo.png" width="30" alt="hedera logo" />
									</a>
								)
								}

							</div>
						</div>
					</form>
						</>
					)}
					
				</div>
			</center>

			<Modal
				ariaHideApp={false}
				isOpen={isOpen}
				onRequestClose={closeModal}
				contentLabel="My dialog"
				className="mymodal"
				overlayClassName="myoverlay"
				closeTimeoutMS={500}
			>
				<div className='close'>
					<button className="btn modal-btn float-right" onClick={closeModal}>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3 16.74L7.76 12L3 7.26L7.26 3L12 7.76L16.74 3L21 7.26L16.24 12L21 16.74L16.74 21L12 16.24L7.26 21L3 16.74m9-3.33l4.74 4.75l1.42-1.42L13.41 12l4.75-4.74l-1.42-1.42L12 10.59L7.26 5.84L5.84 7.26L10.59 12l-4.75 4.74l1.42 1.42L12 13.41Z" /></svg>
					</button>
				</div>
				{(walletLoginEmailAddress && isEmailValidForHashpackLogin) ? (
					<>
						<h3 className='modal-text ml-4 mb-3'>
							scan the QR Code with your HashPack mobile app
						</h3>
						<QRCode value={pairingString} className='rounded mx-auto d-block mt-2 mb-2' />
						<button className="btn--secondary mb-2" onClick={() => { addToClipBoard(pairingString) }}>Copy Pairing String</button>
						<button className="btn--primary mb-2" onClick={browserExtension}>
							<span>Browser Extension</span>
						</button>
					</>
				) : (
					<>
						<br></br> <br></br><br></br> <br></br>
						<h3 className='modal-text ml-1 mt-5 mt-5 mb-3'>
							Please enter your email address.
						</h3>

						<input className='form-control mb-2' placeholder='Enter Email Address' type="email" autoComplete="off" onInput={(e: any) => { handleChange(e.target.value); }} />
						{(walletLoginEmailAddress && !isEmailValidForHashpackLogin && isClicked) ? (
							<>
								<h3 className='modal-text ml-1 mb-3 text-danger'>
									<strong>Please enter a valid email address*</strong>
								</h3>
							</>
						) : (
							<></>
						)
						}
						<button className="btn--secondary mb-2" onClick={() => { validateEmailAddressForHashpakLogin(); setIsClicked(true); }}>Next</button>

					</>
				)

				}


			</Modal>

			<Modal
				ariaHideApp={false}
				isOpen={isFakeWallet}
				onRequestClose={closeFakeWalletModal}
				contentLabel="My dialog"
				className="mymodal2"
				overlayClassName="myoverlay"
				closeTimeoutMS={500}
			>
				<div className='close'>
					<button className="btn modal-btn float-right" onClick={closeFakeWalletModal}>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3 16.74L7.76 12L3 7.26L7.26 3L12 7.76L16.74 3L21 7.26L16.24 12L21 16.74L16.74 21L12 16.24L7.26 21L3 16.74m9-3.33l4.74 4.75l1.42-1.42L13.41 12l4.75-4.74l-1.42-1.42L12 10.59L7.26 5.84L5.84 7.26L10.59 12l-4.75 4.74l1.42 1.42L12 13.41Z" /></svg>
					</button>
				</div>

				<div className='mt-50'>
					<h3 className='modal-text ml-4 mb-3'>
						This address does not exist on Hedera Network.<br></br>
						Please try To login with a different wallet address.
					</h3>
				</div>

				<button className="btn--primary mb-2" onClick={closeFakeWalletModal}>
					<span>Okay</span>
				</button>

			</Modal>


			<Modal
				ariaHideApp={false}
				isOpen={loading}
				contentLabel="My dialog"
				className="loading-modal"
				overlayClassName="myoverlay"
				closeTimeoutMS={500}>
				<div className='d-flex justify-content-center'>
					<h3 className='modal-loading-text' >
						<span>{loadingMessage}</span> <div className="lds-dual-ring"></div>
					</h3>
				</div>
			</Modal>
		</>
	);
}

export default Home;
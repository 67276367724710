import { HashConnect } from 'hashconnect'
import { verify, getAuth, getTokens } from '../services/API'
import { makeBytes } from '../helpers/helpers';

import {
    TransactionId,
    TokenAssociateTransaction,
    TransferTransaction,
    TokenDissociateTransaction,
    AccountId
} from '@hashgraph/sdk';

import getUserId from '../Redux/getUser';
import getUserEmail from '../Redux/getUserEmail';
import updateWalletData from '../Redux/updateWallet';

let hashconnect = new HashConnect()

let appMetadata = {
    name: "TRACE WAR",
    description: "This comic book cover is a Founders Token Exclusive for Founders Token Holders.",
    icon: "https://web3.liithos.com/logo-small-white.svg",
    url: process.env.REACT_APP_BASE_URL
}



export const initConnection = async () => {
    let initData = await hashconnect.init(appMetadata, process.env.REACT_APP_NETWORK, false);
    return initData;
}

export async function manualListener(setIsOpen, setLoading) {
    hashconnect.pairingEvent.once((pairingData) => {
        authenticateUser(pairingData, setLoading)
        setIsOpen(false)
    })
}

export const disconnect = async () => {
    try {
        let initData = await initConnection();
        let topic = initData.topic;
        await hashconnect.disconnect(topic)
        localStorage.clear();
    } catch (error) {

    }
}

export const associate = async () => {
    try {
        let initData = await initConnection();
        const topic = initData.topic;
        const accountId = getUserId()
        const tokenIds = await getTokens();
        let transId = TransactionId.generate(accountId)
        const tx = new TokenAssociateTransaction()
            .setAccountId(accountId)
            .setTokenIds(tokenIds)
            .setNodeAccountIds([new AccountId(3)])
            .setTransactionId(transId)
            .freeze()
        let transBytes = await makeBytes(tx);
        const transaction = {
            topic: topic,
            byteArray: transBytes,
            metadata: {
                accountToSign: accountId,
                returnTransaction: false,
                hideNft: false
            }
        }
        let res = await hashconnect.sendTransaction(topic, transaction);
    } catch (error) {

    }
}

export const disassociate = async () => {
    try {
        let initData = await initConnection();
        const accountId = getUserId()
        const topic = initData.topic;
        const tokenIds = await getTokens();
        let transId = TransactionId.generate(accountId)
        const tx = new TokenDissociateTransaction()
            .setAccountId(accountId)
            .setTokenIds(tokenIds)
            .setNodeAccountIds([new AccountId(3)])
            .setTransactionId(transId)
            .freeze()
        let transBytes = await makeBytes(tx);
        const transaction = {
            topic: topic,
            byteArray: transBytes,
            metadata: {
                accountToSign: accountId,
                returnTransaction: false,
                hideNft: false
            }
        }
        let res = await hashconnect.sendTransaction(topic, transaction);
    } catch (error) {

    }
}



export const signTransaction = async (transBytes) => {
    try {
        let initData = await initConnection();
        const topic = initData.topic;
        const accountId = getUserId()
        const provider = hashconnect.getProvider(process.env.REACT_APP_NETWORK, topic, accountId)
        const signer = hashconnect.getSigner(provider)
        const tx = TransferTransaction.fromBytes(transBytes.data)
        const submit = await tx.executeWithSigner(signer)
        if (submit) {
            return true
        } else {
            return false;
        }

    } catch (error) {

    }
}

export const pairHashpack = async () => {
    try {

        let initData = await initConnection();
        hashconnect.foundExtensionEvent.once((walletMetadata) => {
            hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata);
        })

        hashconnect.foundIframeEvent.once((walletMetadata) => {
            hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata);
        })
    } catch (error) {

    }
}

export const authenticateUser = async (pairingData, setLoading) => {
    try {
        setLoading(true)
        const res = await getAuth();
        setLoading(false)
        const signingData = res.payload
        const serverSigAsArr = Object.values(signingData.serverSignature)
        const serverSigAsBuffer = Buffer.from(serverSigAsArr)
        setLoading(true)
        const auth = await hashconnect.authenticate(pairingData.topic, pairingData.accountIds[0], signingData.serverSigningAccount, serverSigAsBuffer, signingData.payload);
        if (auth.success == true) {
            setLoading(false)
            const walletLoginEmailAddress = getUserEmail()
            console.log(walletLoginEmailAddress)
            const receiveAuthData = {
                signingAccount: pairingData.accountIds[0],
                auth,
                email: walletLoginEmailAddress
            }
            setLoading(true)
            const verificationData = JSON.stringify(receiveAuthData);
            const verification = await verify(verificationData);

            if (verification.data[0].message === 'Success' && verification.data[0].payload != 'This account does not exist') {
                // updateUserData(pairingData.accountIds[0], verification.payload, true)
                localStorage.setItem("accessToken", verification.payload.token)
                window.location.reload();
            } else if (verification.payload != 'This account does not exist') {
                updateWalletData(true)
            }


        }
        setLoading(false)

    } catch (error) {
        // console.log(error)
    }
}


import { SAVE_USER_DATA, SAVE_WALLET_DATA, SAVE_USER_EMAIL } from './actions';
const initialState = {
  userId: '',
  isFirstChapterAssociated: false,
  isSecondChapterAssociated: false,
  isThirdChapterAssociated: false,
  isFirstChapterOwner: false,
  isSecondChapterOwner: false,
  isThirdChapterOwner: false,
  isFounder: false,
  isConnected: false,
  isFakeWallet:false,
  walletLoginEmailAddress:''
};

const userReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case SAVE_USER_DATA:
      return {
        ...state,
        userId: action.payload.userId,
        isFirstChapterAssociated: action.payload.isFirstChapterAssociated,
        isSecondChapterAssociated: action.payload.isSecondChapterAssociated,
        isThirdChapterAssociated: action.payload.isThirdChapterAssociated,
        isFirstChapterOwner: action.payload.isFirstChapterOwner,
        isSecondChapterOwner:  action.payload.isSecondChapterOwner,
        isThirdChapterOwner: action.payload.isThirdChapterOwner,
        isFounder: action.payload.isFounder,
        isConnected: action.payload.isConnected,
      };
    case SAVE_USER_EMAIL:
      return {
        ...state,
        walletLoginEmailAddress: action.payload.walletLoginEmailAddress
      };
    default:
      return state;
  }
};

const walletReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case SAVE_WALLET_DATA:
      return {
        ...state,
        isFakeWallet: action.payload.isFakeWallet
      };
    default:
      return state;
  }
};

 

export  { userReducer, walletReducer  };
